import React, { useEffect, useState } from 'react'
import { ErrorMessage } from '../../components/formik/errorMessage';
import * as Yup from "yup"
import { useFormik } from 'formik';
import { Link } from 'react-router-dom';
import axios from "axios";
import { ROOT_URL_COOMAN } from "../../services/api-url";
import { toast } from "react-toastify";
import authAxios from "../../services/authAxios";
import common from "../../services/common";

export default function Footer2() {
  const [ftrScroll, setFtrScroll] = useState(false)

  const formikFooter = useFormik({
    initialValues: {
      email: "",

    },
    validationSchema: Yup.object({
      email: Yup.string().email("Please enter a valid email address").required("Please enter a email address"),
    }),

    onSubmit: values => {
      submit(values);
    },
  });

  const [socialData, setSocialData] = useState([]);
  const getSocials = () => {
    authAxios
      .get(`${ROOT_URL_COOMAN}/common/social`)
      .then((res) => {
        setSocialData(res.data.data);
      })
      .catch((error) => {
        common.error(error);
      });
  };

  useEffect(() => {
    getSocials();
  }, []);

  const submit = (userData) => {
    axios
      .post(`${ROOT_URL_COOMAN}/common/newsletter`, userData)
      .then((res) => {
        toast.success(res.data.message);
        formikFooter.resetForm();
      }).catch((error) => {
        // common.error(error);
      });
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  useEffect(() => {
    window.addEventListener("scroll", () => {
      setFtrScroll(window.scrollY > 1)
    })
  }, [])
  return (
    <>
      {/* <div className="updateHomeWRp"> */}

      <div className="updfooterWRP">
        <div className="container">
          <div className="fitupddespwrp">
            <div className="fitupddespwrpbox1">
              <div className="fitupddespwrcon">
                <div className="updftrlogocon">
                  <img className="img-fluid" src="/assets/img/Gomedix-White.png" alt="logo" />
                </div>
                <div className="updftrlogotxt">
                  {" "}
                  <p>About Medicx</p>{" "}
                </div>
                <div className="updftrlogotxtsm">
                  {" "}
                  <p>
                    GoMedicx is a home health care partner platform to bring hospital care to your home and enables you
                    to book health care services at your convenience.
                  </p>{" "}
                </div>
              </div>
            </div>
            <div className="fitupddespwrpbox2">
              <div className="updftrlinksusewrp">
                <div className="updftrlinksusecon">
                  <div className="updftrlogotxt">
                    <p>Useful Links</p>
                  </div>
                  <ul>
                    <li>
                      {" "}
                      <Link to="/about">
                        <img className="img-fluid" src="/assets/img/footerrightarrow.png" alt="image" />{" "}
                        <span> About Us</span>
                      </Link>{" "}
                    </li>
                    <li>
                      {" "}
                      <Link to="/works">
                        {" "}
                        <img className="img-fluid" src="/assets/img/footerrightarrow.png" alt="image" />
                        <span> How It Works</span>
                      </Link>{" "}
                    </li>
                    <li>
                      {" "}
                      <Link to="/services">
                        {" "}
                        <img className="img-fluid" src="/assets/img/footerrightarrow.png" alt="image" />
                        <span> Our Services</span>
                      </Link>{" "}
                    </li>
                    {/* <li>
                      {" "}
                      <a>
                        {" "}
                        <img className="img-fluid" src="/assets/img/footerrightarrow.png" alt="image" />
                        <span> Blog</span>
                      </a>{" "}
                    </li> */}
                    <li>
                      {" "}
                      <a href="https://airtable.com/shrbLxLLShO5on62G" target="_blank">
                        {" "}
                        <img className="img-fluid" src="/assets/img/footerrightarrow.png" alt="image" />
                        <span>Join GoMedicx</span>
                      </a>{" "}
                    </li>
                    <li>
                      {" "}
                      <Link to="/contact">
                        {" "}
                        <img className="img-fluid" src="/assets/img/footerrightarrow.png" alt="image" />
                        <span> Contact Us</span>
                      </Link>{" "}
                    </li>
                  </ul>
                </div>
                <div className="updftrlinksusecon">
                  <div className="updftrlogotxt">
                    <p>Legal</p>
                  </div>
                  <ul>
                    <li>
                      {" "}
                      <Link to="privacy-policy">
                        {" "}
                        <img className="img-fluid" src="/assets/img/footerrightarrow.png" alt="image" />
                        <span>Privacy Policy</span>
                      </Link>{" "}
                    </li>
                    <li>
                      {" "}
                      <Link to="terms-and-conditions">
                        <img className="img-fluid" src="/assets/img/footerrightarrow.png" alt="image" />{" "}
                        <span> Terms & Conditions</span>
                      </Link>{" "}
                    </li>
                    <li>
                      {" "}
                      <Link to="cancelling-and-refund-policy">
                        {" "}
                        <img className="img-fluid" src="/assets/img/footerrightarrow.png" alt="image" />
                        <span>Cancellation & Refund Policy</span>
                      </Link>{" "}
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="fitupddespwrpbox3">
              <div className="updftrlogotxt">
                {" "}
                <p>Subscribe to Our Newsletter</p>{" "}
              </div>
              <div className="updftrlogotxtsm">
                {" "}
                <p>
                  Keep up with latest news and events. Subscribe to <br /> our newsletter
                </p>{" "}
              </div>
              <div className="updatehdrinpCON ftr">
                <form onSubmit={formikFooter.handleSubmit}>
                  <div className="form-group">
                    <input
                      {...formikFooter.getFieldProps("email")}
                      type="text"
                      className="form-control"
                      placeholder="Enter Your Email"
                    />
                    <button type="submit">
                      <img className="img-fluid" src="/assets/img/teligramupd.png" alt="image" />
                    </button>
                  </div>
                  <ErrorMessage formik={formikFooter} name="email" />
                </form>
              </div>
              <div className="updftrlogotxt">
                {" "}
                <p>Follow Us</p>{" "}
              </div>
              <div className="updftrsociallinkwrp">
                <ul>
                  <li>
                    <a href="https://www.facebook.com/gomedicx" target="_blank">
                      <img className="img-fluid" src="/assets/img/facebookupd.svg" alt="image" />
                    </a>
                  </li>
                  <li>
                    <a href="https://instagram.com/gomedicx?igshid=ZDdkNTZiNTM=" target="_blank">
                      <img className="img-fluid" src="/assets/img/instaupd.svg" alt="image" />
                    </a>
                  </li>
                  <li>
                    <a href="https://www.linkedin.com/company/gomedicx/" target="_blank">
                      <img className="img-fluid" src="/assets/img/linkedinupd.svg" alt="image" />
                    </a>
                  </li>
                  {/* <li>
                    <a href="https://www.twitter.com" target="_blank">
                      <img className="img-fluid" src="/assets/img/twitterupd.png" alt="image" />
                    </a>
                  </li> */}
                  <li>
                    <a href="https://www.youtube.com/@gomedicx" target="_blank">
                      <img className="img-fluid" src="/assets/img/youtubeupd.svg" alt="image" />
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="updftrcopyrightwrp">
        Copyrights © GoMedicx24 Healthcare Solutions Pvt. Ltd. 2022. All Rights Reserved.
        <div className={ftrScroll ? "scrolledFtr" : "copyrightimgupd"}>
          <img onClick={scrollToTop} className="scrollToTop img-fluid" src="/assets/img/copyright.svg" alt="image" />
        </div>
      </div>
      {/* </div > */}
    </>
  );
}

{
  /* <li><a href={socialData?.filter((d) => d.type === "facebook")[0]?.link} target="_blank"><img className="img-fluid" src="/assets/img/facebookupd.png" alt="image" /></a> </li>
                  <li><a href={socialData?.filter((d) => d.type === "instagram")[0]?.link} target="_blank"><img className="img-fluid" src="/assets/img/instaupd.png" alt="image" /></a> </li>
                  <li><a href={socialData?.filter((d) => d.type === "linkedin")[0]?.link} target="_blank"><img className="img-fluid" src="/assets/img/linkedinupd.png" alt="image" /></a> </li>
                  <li><a href={socialData?.filter((d) => d.type === "twitter")[0]?.link} target="_blank"><img className="img-fluid" src="/assets/img/twitterupd.png" alt="image" /></a> </li>
                  <li><a href={socialData?.filter((d) => d.type === "youtube")[0]?.link} target="_blank"><img className="img-fluid" src="/assets/img/youtubeupd.png" alt="image" /></a> </li> */
}
