import { useFormik } from "formik";
import { toast } from "react-toastify";
import { reactLocalStorage } from "reactjs-localstorage";
import * as Yup from 'yup';
import apiFunc from "./api";

const common = {
  loader: (type) => {
    if (type) {
      document.body.className = 'loading_page';
    } else {
      document.body.className = document.body.className.replace("loading_page", "");
    }
  },
  base64Mime: (encoded) => {
    var result = null;
    if (typeof encoded !== 'string') {
      return result;
    }
    var mime = encoded.match(/data:([a-zA-Z0-9]+\/[a-zA-Z0-9-.+]+).*,.*/);
    if (mime && mime.length) {
      result = mime[1];
    }
    return result;
  },
  base64ReadFile: (base64String) => {
    var nonBlob = base64String == undefined ? 0 : base64String.length;
    var filetype = common.base64Mime(base64String)
    var datass = { size: nonBlob / 1000, type: filetype }
    return datass;
  },
  previewURL: (file) => {
    let URL = '';
    if (file != '') {
      URL = window.URL.createObjectURL(file)
    }
    return URL;
  },
  mineTypeValidate: (value) => {
    if (value == undefined || value == null) {
      return false;
    }
    let fileType = value.type;
    return value && (
      fileType === "image/jpeg" ||
      fileType === "image/bmp" ||
      fileType === "image/png"/* ||
      fileType === 'application/pdf' ||
      fileType === "application/msword" */
    )
  },
  fileSizeValidate: (value, size) => {
    if (value == undefined || value == null) {
      return false;
    }
    let fileSize = value.size
    if (!fileSize) {
      fileSize = 2
    }
    let mb = fileSize * 1024;
    return fileSize <= mb
  },
  getMiles: (i) => {
    return (i * 0.000621371192).toFixed(1);
  },
  coupanTypeDiscount: (obj) => {
    let ctype = obj.couponType || 0;
    let price = obj.price || 0;
    let discount = obj.discount || 0;
    let minAmount = obj.minAmount || 0;
    let disUpto = obj.disUpto || 0;
    let disRate = 0;
    if (ctype == "FLAT PERCENT") {
      disRate = price >= minAmount ? (price * discount) / 100 : disRate;
    } else if (ctype == "FLAT PERCENT UPTO") {
      disRate = price >= minAmount ? (price * discount) / 100 : disRate;
      disRate = disRate <= disUpto ? disRate : disUpto;
    } else if (ctype == "CASH DISCOUNT UPTO") {
      disRate = price >= minAmount ? discount : disRate;
      disRate = disRate <= disUpto ? disRate : disUpto;
    }
    return parseFloat(disRate.toFixed(2));
  },
  isMobile: (num) => {
    var isphone = /^(1\s|1|)?((\(\d{3}\))|\d{3})(\-|\s)?(\d{3})(\-|\s)?(\d{4})$/.test(num);
    return isphone;
  },
  coordinateLocal: async () => {
    let coordataL;
    function asignData(data) {
      coordataL = data
    }
    await navigator.geolocation.getCurrentPosition(function (position) {
      let lat = position.coords.latitude;
      let lng = position.coords.longitude;
      let jsonCoords = {
        lat: lat,
        lng: lng
      }
      jsonCoords = JSON.stringify(jsonCoords);
      asignData(jsonCoords)
      localStorage.setItem('geoLocal', jsonCoords);
      // reactLocalStorage.set('geoLocal', jsonCoords);
    })
    return coordataL;
  },
  creditCardType: (cardType) => {
    let imageUrl;
    cardType = cardType.toLowerCase();
    switch (cardType) {
      case "visa":
        imageUrl = "card-logo-visa.svg";
        break;
      case "mastercard":
        imageUrl = "card-logo-mastercard.svg";
        break;
      case "american-express":
        imageUrl = "card-logo-amex.svg";
        break;
      default:
        imageUrl = "card-logo-unknown.svg";
    }
    return imageUrl;
  },
  imageValidate: (extention, size, msg) => {
    extention = extention || ['image/jpg', 'image/jpeg', 'image/png']
    msg = msg || "You need to attach image";
    size = size || 1024 * 1024
    let image = Yup
      .mixed()
      .required(msg)
      .test("type", `Please upload valid image format i.e. jpg, jpeg, png`, (value) => {
        if (value.type == undefined || value.type == null || value.type == '') {
          return false;
        }
        let fileType = value.type;
        return value && (extention.indexOf(fileType) != -1);
      })
      .test("fileSize", "The image is too large", (value) => {
        if (value.size == undefined || value.size == null || value.size == '') {
          return false;
        }
        return value && value?.size <= size;
      })
    return image;
  },
  profileImageValidate: (extention, size, msg) => {
    extention = extention || ['image/jpg', 'image/jpeg', 'image/png']
    msg = msg || "You need to attach image";
    size = size || 1024 * 1024
    let image = Yup
      .mixed()
      .required(msg)
      .test("type", `Please upload valid image format i.e. jpg, jpeg, png`, (value) => {
        if (value.type == undefined || value.type == null || value.type == '') {
          return false;
        }
        let fileType = value.type;
        return value && (extention.indexOf(fileType) != -1);
      })
      .test("fileSize", "Image size should be less than 2 mb", (value) => {
        if (value.size == undefined || value.size == null || value.size == '') {
          return false;
        }
        return value && value?.size <= size;
      })
    return image;
  },

  uploadImage: async (values) => {
    let data;
    common.loader(true);
    const formData = new FormData();
    formData.append("coverImage", values.userImage);
    data = await apiFunc.postUpload(formData).then(res => {
      return res.data.data._id
    }).catch((error) => {
      common.error(error);
    })
    common.loader(false);
    return data;
  },
  uploadRequestImage: async (values) => {
    if (values.userImage.size <= 1048576) {
      let data;
      common.loader(true);
      const formData = new FormData();
      formData.append("coverImage", values.userImage);
      data = await apiFunc.postUpload(formData).then(res => {
        return res.data.data._id
      })
        .catch((error) => {
          common.error(error);
        })
      common.loader(false);
      return data;
    }
  },
  uploadAudio: async (values) => {
    let data;
    common.loader(true);
    const formData = new FormData();
    formData.append("coverImage", values.userImage);
    data = await apiFunc.postUploada(formData).then(res => {
      return res.data.data._id
    }).catch((error) => {
      common.error(error);
    })
    common.loader(false);
    return data;
  },
  error: (error) => {
    var message = JSON.parse(error.request.response).message;
    toast.error(message);
    return message;
  },
  generateQOID: async () => {
    let resData = null
    resData = await apiFunc.getOptionId().then(res => {
      return res.data.data
    }).catch((error) => {
      common.error(error);
    })

    return resData;
  },
  isAlphabet: (event) => {
    return (event.charCode >= 65 && event.charCode <= 90) || (event.charCode >= 97 && event.charCode <= 122);
  },
  percentageAmt: (Amt, Perctage) => {
    let perAmt = (Amt * Perctage) / 100
    let remAmt = Amt - perAmt;
    return remAmt;
  },
  newDateTime: (d) => {
    let date = new Date(d)
    let todayDate = new Date()
    let hours = date.getHours();
    let minutes = date.getMinutes();
    let seconds = date.getSeconds();
    let getFullYear = todayDate.getFullYear();
    let getMonth = todayDate.getMonth();
    let getDate = todayDate.getDate();

    //new Date(year, month, day, hours, minutes, seconds, milliseconds)

    let newDate = new Date(getFullYear, getMonth, getDate, hours, minutes, seconds, 0)
    return newDate;
  },
  newDateFromToday: (d) => {
    let date = new Date(d)
    let todayDate = new Date()
    let hours = date.getHours();
    let minutes = date.getMinutes();
    let seconds = date.getSeconds();
    let getFullYear = todayDate.getFullYear();
    let getMonth = todayDate.getMonth();
    let getDate = todayDate.getDate();
    let day = date.getDay()
    let todayday = todayDate.getDay()
    let newDate = new Date(getFullYear, getMonth, getDate, hours, minutes, seconds, 0)
    let returnData = day <= todayday ? newDate : date
    return returnData;
  },

  addDays: (date, days) => {
    let copy = new Date(Number(date))
    copy.setDate(date.getDate() + days)


    return copy
  },
  leapYear: (year) => {
    if (year % 4 === 0 && (year % 100 !== 0 || year % 400 === 0)) {
      return 366;
    } else {
      return 365;
    }
  },
  inpAlphabet: (e) => {
    let realphate = e.replace(/[^A-Za-z\s]/ig, '')
    return realphate
  }
};

export default common