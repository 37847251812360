import React, { lazy, Suspense, useContext, useEffect } from 'react'
import { Route, Routes, useLocation } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import Loader from '../components/common/loader';
import ScrollToTop from '../components/scrollToTop/ScrollToTop';
import { UserContext } from '../context/theme';
import Footer2 from '../pages/updateHome/footer2';
import Header2 from '../pages/updateHome/header2';
import ROOT_URL from '../services/api-url';
import authAxios from '../services/authAxios';
import common from '../services/common';
// import Header from './header';
import Sidebar from './sidebar';
import ReactGA from 'react-ga';


// const Home = lazy(() => import("../pages/home"));
const Home2 = lazy(() => import("../pages/updateHome/home2"));
const Services2 = lazy(() => import("../pages/updateHome/services2"));
const Contact2 = lazy(() => import("../pages/updateHome/contact2"));
const Works2 = lazy(() => import("../pages/updateHome/works2"));
const About2 = lazy(() => import("../pages/updateHome/about2"));
const NotFound = lazy(() => import("../pages/notfound"));
const Login = lazy(() => import("../pages/login"));
const Otp = lazy(() => import("../pages/otp"));
const Signup = lazy(() => import("../pages/signup"));
const Dashboard = lazy(() => import("../pages/dashboard"));
const NewRequest = lazy(() => import("../pages/newRequest"));
const Followups = lazy(() => import("../pages/followUps"));
const RecentRequest = lazy(() => import("../pages/recentRequest"));
const Requestvisit = lazy(() => import("../pages/requestVisit"));
const Payment = lazy(() => import("../pages/payment"));
const RequestSend = lazy(() => import("../pages/requestSend"));
const Notification = lazy(() => import('../pages/notification'));
const Consultation = lazy(() => import('../pages/consultations'));
const Scheduler = lazy(() => import('../pages/scheduler'));
const PaymentSuccessful = lazy(() => import('../pages/paymentSucces'));
const TreatmentCompleted = lazy(() => import('../pages/treatmentCompleted'));
const FeedBack = lazy(() => import('../pages/feedBack'));
const AddScheduler = lazy(() => import('../pages/addScheduler'));
const ScheduleView = lazy(() => import('../pages/scheduleView'));
const ScheduleEdit = lazy(() => import('../pages/scheduleedit'));
const RequestDetailsUnpaid = lazy(() => import('../pages/requestDetailsUnpaid'));
const RequestCompleted = lazy(() => import('../pages/requestAllPaid'));
const RequestDetailsWithoutDoctor = lazy(() => import('../pages/requestDeatailWithoutDoctor'));
const BasicCheckup = lazy(() => import('../pages/basicCheckupDetails'));
const BasicCheckupFillDetails = lazy(() => import('../pages/basicCheckupFillDetails'));
const ProFile = lazy(() => import('../pages/profile'));
const ProfileEdit = lazy(() => import('../pages/profileEdit'));
// const About = lazy(() => import('../pages/about'));
const PrivacyPolicy = lazy(() => import('../pages/privacyPolicy'));
const TermsConditions = lazy(() => import('../pages/terms'));
const Faq = lazy(() => import('../pages/faq'));
const ContactUS = lazy(() => import('../pages/contact'));
const Cancelling = lazy(() => import('../pages/cancellingPolicy'));
const ViewRequest = lazy(() => import('../pages/requestAllPaid'));
const ShowBasicCheck = lazy(() => import("../pages/doctor/doctor-basic-checkup"));
const FillEPrescription = lazy(() => import("../pages/doctor/fill-epres"));
const FillEPrescriptionSuccess = lazy(() => import("../pages/doctor/success"));
const ShowEPrescription = lazy(() => import("../pages/e-precription"));
const PaymentSuccess = lazy(() => import('../pages/paysuccess'));
const ShowingPdf = lazy(() => import('../pages/showPdf'));


ReactGA.initialize(process.env.REACT_APP_TRACKING_ID);

export default function Layout() {



  useEffect(() => {
    window.history.scrollRestoration = 'manual'
  }, []);

  const { auth } = useContext(UserContext)
  const { pathname } = useLocation();

  function checkHeader(pathname) {
    let array = ['/login', "/otp/", "/signup"]
    let filtrArr = array.filter((data) => {
      return pathname.search(data) != -1
    })
    return filtrArr.length < 1
  }


  useEffect(() => {
    // ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);


  return (
    <div className="main_app">
      <ToastContainer />
      <ScrollToTop />

      {/* {checkHeader(pathname) && (
        <Header />

      )} */}
      {checkHeader(pathname) && (
        <Header2 />
      )}

      {auth && (
        <Sidebar />
      )}
      <div className="gomedix-min-height">

        <Suspense fallback={<Loader />}>
          <Routes>
            <Route path="*" element={<NotFound />} />
            <Route path="/" element={auth ? <Dashboard /> : <Home2 />} />
            <Route path="/home" element={auth ? <Dashboard /> : <Home2 />} />
            <Route path="/signup/:id" element={<Signup />} />
            <Route path="/login" element={<Login />} />
            <Route path="/otp/:id/:phone" element={<Otp />} />
            <Route path="/dashboard" element={<Dashboard />} />
            <Route path="/new-request" element={<NewRequest />} />
            <Route path="/recent-request" element={<RecentRequest />} />
            <Route path="/follow-ups" element={<Followups />} />
            <Route path="/request-visit" element={<Requestvisit />} />
            <Route path="/payment" element={<Payment />} />
            <Route path="/request-send" element={<RequestSend />} />
            <Route path="/notification-alerts" element={<Notification />} />
            <Route path="/my-consultations" element={<Consultation />} />
            <Route path="/scheduler" element={<Scheduler />} />
            <Route path="/payment-successful" element={<PaymentSuccessful />} />
            <Route path="/treatment-completed" element={<TreatmentCompleted />} />
            <Route path='/feedback/:id' element={<FeedBack />} />
            <Route path='/add-scheduler' element={<AddScheduler />} />
            <Route path='/schedule-view/:id' element={<ScheduleView />} />
            <Route path='/request-details-unpaid' element={<RequestDetailsUnpaid />} />
            <Route path='/request-details-allpaid' element={<RequestCompleted />} />
            <Route path='/request-details-without-doctor-completed' element={<RequestDetailsWithoutDoctor />} />
            <Route path='/fill-basic/:id' element={<BasicCheckup />} />
            <Route path='/fill-basic-details/:id' element={<BasicCheckupFillDetails />} />
            <Route path='/profile' element={<ProFile />} />
            <Route path='/profile-edit' element={<ProfileEdit />} />
            {/* <Route path='/about' element={<About />} /> */}
            <Route path='/privacy-policy' element={<PrivacyPolicy />} />
            <Route path='/terms-and-conditions' element={<TermsConditions />} />
            <Route path='/faq' element={<Faq />} />
            <Route path='/contact-us' element={<ContactUS />} />
            <Route path='/cancelling-and-refund-policy' element={<Cancelling />} />
            <Route path="/request/:id" element={<ViewRequest />} />
            <Route path="/doctor/view-basic-check-up/:id/:idx" element={<ShowBasicCheck />} />
            <Route path="/doctor/fill-prescription/:id/:idx" element={<FillEPrescription />} />
            <Route path="/doctor/success" element={<FillEPrescriptionSuccess />} />
            <Route path="/show-prescription/:id" element={<ShowEPrescription />} />
            <Route path="/payment-success" element={<PaymentSuccess />} />
            <Route path="/scheduleedit/:id" element={<ScheduleEdit />} />
            <Route path="/show-pdf" element={<ShowingPdf />} />
            <Route path="/services" element={<Services2 />} />
            <Route path="/works" element={<Works2 />} />
            <Route path="/contact" element={<Contact2 />} />
            <Route path="/about" element={<About2 />} />

          </Routes>
        </Suspense>
      </div>

      {/* <Footer /> */}
      <Footer2 />
    </div>
  )
}
