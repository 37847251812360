import React, { useContext } from 'react'
import { Link} from 'react-router-dom'
import { UserContext } from '../context/theme'
export default function Sidebar() {
  const {sidebarActive, setSidebarActive, closeSider, logOut } = useContext(UserContext);
  

  return (
    <>
      <div className={`backdropSidebar ${sidebarActive?'active':''}`} onClick={()=>setSidebarActive(!sidebarActive)}>
        <a className={`closeSiderbar`}><img className='img-fluid' src='/assets/img/close.png' alt='#'/></a>
      </div>      
      <div className={`sidebarWrp ${sidebarActive?'active':''}`}>
        <ul>
          <li>
            <Link onClick={()=>setSidebarActive(!sidebarActive)}  to='/dashboard' className='active'>Dashboard</Link>
          </li>
          <li>
            <Link onClick={()=>setSidebarActive(!sidebarActive)} to='/profile'>Profile</Link>
          </li>
          <li>
            <Link onClick={()=>setSidebarActive(!sidebarActive)} to='/request-visit'>Request a visit</Link>
          </li>
          <li>
            <Link onClick={()=>setSidebarActive(!sidebarActive)} to='/recent-request'>My Consultations</Link>
            {/* archana ke bolne ke baad */}
          </li>
          <li>
            <Link onClick={()=>setSidebarActive(!sidebarActive)} to='/scheduler'>GoMedicx Scheduler</Link>
          </li>
          <li>
            <a onClick={()=> logOut()}>Sign Out</a>
          </li>
        </ul>
      </div>
    </>
  )
}
