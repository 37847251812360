import React, { useContext, useState, useEffect } from 'react'
import { Link, NavLink, useNavigate, useParams } from 'react-router-dom'
import { useFormik } from 'formik';
import * as yup from 'yup';
import axios from 'axios';
import ROOT_URL, {ROOT_URL_COOMAN} from '../services/api-url'
import { ErrorMessage } from '../components/formik/errorMessage';
import ReactInputMask from 'react-input-mask';
import authAxios from '../services/authAxios';
import common from "../services/common";
import { UserContext } from "../context/theme";


export default function Login() {
	const [sideMenuActive, setSideMenuActive] = useState(false)
	const [filterMenu, setFilterMenu] = useState(true)
	const params = new URLSearchParams(window.location.search);
	let redirect = params.get("redirect");
	const { auth } = useContext(UserContext)

	const [socialData, setSocialData] = useState([]);
    const getSocials = () => {
        authAxios
        .get(`${ROOT_URL_COOMAN}/common/social`)
        .then((res) => {
					setSocialData(res.data.data);
        })
        .catch((error) => {
            common.error(error);
        });
    };

    useEffect(() => {
        getSocials();
    }, []);

	let navigate = useNavigate();
    useEffect(() => {
      if (auth && redirect) {
        navigate(redirect);
      }
    }, [auth]);

	const tabbingMenu = (type) => {
		let newTabval = type == filterMenu ? "" : type;
		setFilterMenu(newTabval);
	};

	const initialValues = {
		phone: "",
	};
	const validationSchema = yup.object({
		phone: yup.string().min(10, 'Please enter a valid phone no.').required('Please enter phone no.'),
	});
	const formik = useFormik({
		initialValues,
		validationSchema,
		onSubmit: (values) => {
			login(values)
		},
	});
	const login = (userData) => {
		axios
			.post(`${ROOT_URL}/customer/CheckMobile`, userData)
			.then((res) => {
				localStorage.setItem("otp", res.data.data.otp);
				localStorage.setItem("phone", userData.phone);

        if(redirect){
          navigate(`/otp/${res.data.data._id}/${userData.phone}?redirect=${redirect}`)
        }else{
          navigate(`/otp/${res.data.data._id}/${userData.phone}`)
        }
			}).catch((error) => {
				// common.error(error);
			});

	};


	return (
		<>
			<div className="loginWrp">
				<div className="loginHerdWrp">
					<div className="container">
						<div className="row align-items-center">
							<div className="col-3">
								<div className="updnavlogo2img loginHader">
									<Link to="/home" className=" home-logo-popup updnavlogo2img"><img className="img-fluid" src="/assets/img/Gomedix-White.png" alt="#" /></Link>
								</div>
							</div>
							<div className="col-9">
								<div className="updatenavlinkcon flexend">
									<ul>
										<li><NavLink to="/about"> <div className="updnavlinktxt">About us </div> </NavLink></li>
										<li><NavLink to="/services" > <div className="updnavlinktxt"> Services</div> </NavLink></li>
										<li><NavLink to="/works" > <div className="updnavlinktxt">How It Works </div> </NavLink></li>
										<li><NavLink to="/contact"> <div className="updnavlinktxt">Contact Us </div> </NavLink></li>
										<li><a href={socialData?.filter((d) => d.type === "partner-link")[0]?.link} target="_blank"> <div className="updnavlinkbtn partlink">Partner With Us </div> </a></li>
									</ul>
									<div className="loginHader login">
										<Link to="/" className="closeLogBx"><img className="img-fluid" src="/assets/img/close.png" alt="#" /></Link>
									</div>
								</div>
								<div className="menushowwrpupd">
									<div className='navmenumainwrp txtright' onClick={() => setSideMenuActive(!sideMenuActive)}>
										<span onClick={() => tabbingMenu("search")}><i className="fas fa-bars"></i></span>
									</div>
									<div className={`updhomemenuWRPAlt barupd ${sideMenuActive ? 'active' : ''}`} onClick={() => setSideMenuActive(!sideMenuActive)}>
									</div>
									<div className={`updhomemenumenuwrp ${sideMenuActive ? 'active' : ''}`}>
										<div className="navupdflexcon">
											<div className="updatenavlinkcon">
												<ul>
													<li><NavLink to="/about"> <div className="updnavlinktxt" onClick={() => setSideMenuActive(!sideMenuActive)}>About us </div> </NavLink></li>
													<li><NavLink to="/services" > <div className="updnavlinktxt" onClick={() => setSideMenuActive(!sideMenuActive)}> Services</div> </NavLink></li>
													<li><NavLink to="/works" > <div className="updnavlinktxt" onClick={() => setSideMenuActive(!sideMenuActive)}>How It Works </div> </NavLink></li>
													<li><NavLink to="/contact"> <div className="updnavlinktxt" onClick={() => setSideMenuActive(!sideMenuActive)}>Contact Us </div> </NavLink></li>
													<li><a href={socialData?.filter((d) => d.type === "partner-link")[0]?.link} target="_blank"> <div className="updnavlinkbtn partlink" >Partner With Us </div> </a></li>
												</ul>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className="LoginFormWrp">
					<div className="container">
						<div className="row">
							<div className="col-lg-12">
								<div className="loginFormContWrp">
									<div className="loginTitleBx">
										<h2>Sign Up/Sign In</h2>
									</div>
									<form className="LoginformBx" onSubmit={formik.handleSubmit} >
										<div className="lgoinFormBX">
											<div className="form-group">
												<p>Please enter your Phone No. to receive a One Time Password (OTP) to Sign In.</p>
												<ReactInputMask
													className="form-control" {...formik.getFieldProps('phone')} mask="9999999999" maskChar={null} placeholder="Phone No." />
												{/* <input type="text" className="form-control" placeholder="Phone No." {... formik.getFieldProps('phone')} /> */}
												<ErrorMessage formik={formik} name="phone" />
											</div>
										</div>
										<div className="loginButtnBx">
											<button type="submit" className="btn-them-03">Next</button>
										</div>
									</form>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	)
}
