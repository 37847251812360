import React, { createContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Layout from "../layout/layout";
import Login from "../pages/login";
import ROOT_URL from "../services/api-url";
import authAxios from "../services/authAxios";
import common from "../services/common";

export const UserContext = createContext();

// This context provider is passed to any component requiring the context
export const UserProvider = ({ children }) => {
  let navigate = useNavigate();
  const [auth, setAuth] = useState(false);
  const [Theme, setTheme] = useState("theme-4");
  const [ThemeColor, setThemeColor] = useState("theme-color-1");
  const [MiniSidebar, setMiniSidebar] = useState("");
  const [userInfo, setUserInfo] = useState({});
  const [sidebarActive, setSidebarActive] = useState(false);
  const [profileData, setProfileData] = useState({});
  const [schedular, setScheduler] = useState(0);
  const [notificationAlert, setNotificationAlert] = useState(0)
  const [requestId, setRequestId] = useState()


  const setProfile = () => {
    authAxios({
      method: "POST",
      url: `${ROOT_URL}/user/profile`,
    }).then((res) => {
      setProfileData(res.data.data)
    }).catch((error) => {
      common.error(error);
    });

  };
  useEffect(() => {
    /* let thm = localStorage.getItem('theme') || "theme-1";
    let thmClr = localStorage.getItem('themeColor'); */
    let authLocal = localStorage.getItem('token');
    /* setTheme(thm);
    setThemeColor(thmClr); */
    setAuth(authLocal);
  }, [])
  const reset = () => {
    setSidebarActive(false)
    setMiniSidebar("")
    setUserInfo({})
  }

  const refresh = () => {
    let token = localStorage.getItem('token');
    if (token) {
      authAxios.get(`${ROOT_URL}/user/token/refresh`).then((res) => {
        localStorage.setItem("token", res?.data?.token)
      }).catch((error) => {
        common.error(error);
      });
    }
  };
  useEffect(() => {
    let myInterval = setInterval(refresh, 10000)
    if (!auth) {
      ;
      return () => clearInterval(myInterval);
    }
  }, [auth])


  const logOut = () => {
    localStorage.clear();
    setAuth(false)
    reset()
    navigate(`/`)
  };
  return (
    <UserContext.Provider
      value={{
        ThemeColor,
        Theme,
        auth,
        MiniSidebar,
        setThemeColor,
        setTheme,
        setAuth,
        setMiniSidebar,
        userInfo, setUserInfo,
        sidebarActive, setSidebarActive,
        logOut,
        setProfile, profileData,
        schedular, setScheduler,
        notificationAlert, setNotificationAlert,
        requestId, setRequestId
      }}
    >
      {children}
    </UserContext.Provider>
  );
};