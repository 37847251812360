import React, { useContext, useEffect, useState } from 'react'
import { Link, NavLink } from 'react-router-dom';
import { UserContext } from '../../context/theme';
import ROOT_URL, { ROOT_URL_COOMAN } from '../../services/api-url';
import authAxios from '../../services/authAxios';
import common from "../../services/common";


export default function Header2() {
    const [sideMenuActive, setSideMenuActive] = useState(false)
    const [scroll, setScroll] = useState(false)
    const [backToTop, setBackToTop] = useState(false);
    const [filterMenu, setFilterMenu] = useState(true)
    const { auth, sidebarActive, setSidebarActive, setProfile, profileData, notificationAlert, setNotificationAlert } = useContext(UserContext)
    const [relate, setRelate] = useState(false)
    useEffect(() => {
        if (auth) {
            setProfile();
        }
    }, [auth])


    const getNotificationBadge = async () => {
        await authAxios({
            method: "POST",
            url: `${ROOT_URL}/notification/notification_read/`,

        }).then((res) => {
            setNotificationAlert(res.data.unread)
        }).catch((error) => {
            console.log(error);
        })
    }

    const [socialData, setSocialData] = useState([]);
    const getSocials = () => {
        authAxios
            .get(`${ROOT_URL_COOMAN}/common/social`)
            .then((res) => {
                setSocialData(res.data.data);
                console.log(res.data.data)
            })
            .catch((error) => {
                common.error(error);
            });
    };

    useEffect(() => {
        getSocials();
    }, []);

    useEffect(() => {
        if (auth) {
            getNotificationBadge()
        }
    }, [auth])

    useEffect(() => {
        window.addEventListener("scroll", () => {
            if (window.pageYOffset > 350) {
                setBackToTop(true);
            } else {
                setBackToTop(false);
            }
        });
    }, []);


    useEffect(() => {
        window.addEventListener("scroll", () => {
            setScroll(window.scrollY > 1)
        })
    }, [])

    const tabbingMenu = (type) => {
        let newTabval = type == filterMenu ? "" : type;
        setFilterMenu(newTabval);
    };





    return (
        <>
            <div className="updateHomeWRp">
                <div className="homemainheaderwrp">
                    <div className={relate ? "updatenavbarwrp" : auth ? "postireal" : "updatenavbarwrp"}>
                        <div className={scroll ? "main-header scrolled" : auth ? "main-header white" : "main-header"} >
                            {/* <div className={auth ? "main-header scrolled" : "main-header"} > */}
                            <div className="container">
                                <div className="navupdflexWRP">
                                    <div className="navupdflexcon">
                                        <div className="updnavlogo2img">
                                            <Link to="/home"><img className="img-fluid" src="/assets/img/logoupdate.png" alt="image" /></Link>
                                        </div>
                                    </div>
                                    <div className="navupdflexcon dsnon">
                                        <div className="updatenavlinkcon">
                                            {auth ? (
                                                <div className="userNavBar">
                                                    <ul>
                                                        <li>
                                                            <Link to="/request-visit">
                                                                <span className="btn-them-04">Request A VISIT</span>
                                                            </Link>
                                                        </li>
                                                        <li>
                                                            <Link to="/dashboard"><i className="fas fa-address-card"></i></Link>
                                                        </li>
                                                        <li className="notification_alert notification_badge_div">
                                                            {notificationAlert != "0" ? <div className='notification_badge'></div> : null}
                                                            {/* <div className='notification_badge'></div> */}
                                                            <Link to="/notification-alerts"><i className="fas fa-bell"></i></Link>
                                                        </li>
                                                        <li>
                                                            <Link to="/profile">
                                                                <div className="userHesrImg">
                                                                    <img className="img-fluid" src={`${profileData?.image ? profileData?.image?.path : '/assets/img/usrdammy.png'}`} alt="#" />
                                                                    <h6>{profileData?.name?.trim()?.split(' ').slice(0, 2).join(' ')}</h6>
                                                                </div>
                                                            </Link>
                                                        </li>
                                                        <li className='sidebarLink'>
                                                            <a onClick={() => setSidebarActive(!sidebarActive)}><i className="fas fa-bars"></i></a>
                                                        </li>
                                                    </ul>
                                                </div>
                                            ) : (
                                                <ul>
                                                    <li><NavLink to="/about"> <div className="updnavlinktxt">About us </div> </NavLink></li>
                                                    <li><NavLink to="/services" > <div className="updnavlinktxt"> Services</div> </NavLink></li>
                                                    <li><NavLink to="/works" > <div className="updnavlinktxt">How It Works </div> </NavLink></li>
                                                    <li><NavLink to="/contact"> <div className="updnavlinktxt">Contact Us </div> </NavLink></li>
                                                    <li><Link to="/login"> <div className="updnavlinkbtn "> Sign in / Signup</div> </Link></li>
                                                    <li><a href={socialData?.filter((d) => d.type === "partner-link")[0]?.link} target="_blank"> <div className="updnavlinkbtn partlink">Partner With Us </div> </a></li>
                                                </ul>
                                            )}
                                        </div>
                                    </div>
                                    <div className="menushowwrpupd">
                                        {auth ? (
                                            <>
                                                <div className="userNavBar">
                                                    <ul>
                                                        <li>
                                                            <Link to="/request-visit">
                                                                <span className="btn-them-04">Request A VISIT</span>
                                                            </Link>
                                                        </li>
                                                        <li>
                                                            <Link to="/dashboard"><i className="fas fa-address-card"></i></Link>
                                                        </li>
                                                        <li className="notification_alert notification_badge_div">
                                                            {notificationAlert != "0" ? <div className='notification_badge'></div> : null}
                                                            {/* <div className='notification_badge'></div> */}
                                                            <Link to="/notification-alerts"><i className="fas fa-bell"></i></Link>
                                                        </li>
                                                        <li>
                                                            <Link to="/profile">
                                                                <div className="userHesrImg">
                                                                    <img className="img-fluid" src={`${profileData?.image ? profileData?.image?.path : '/assets/img/usrdammy.png'}`} alt="#" />
                                                                    <h6>{profileData?.name?.trim()?.split(' ')?.slice(0, 2)?.join(' ')}</h6>
                                                                </div>
                                                            </Link>
                                                        </li>
                                                        <li className='sidebarLink'>
                                                            <a onClick={() => setSidebarActive(!sidebarActive)}><i className="fas fa-bars"></i></a>
                                                        </li>
                                                    </ul>
                                                </div>

                                            </>
                                        ) : (
                                            <>
                                                <div className='navmenumainwrp' onClick={() => setSideMenuActive(!sideMenuActive)}>
                                                    <span><img onClick={() => tabbingMenu("search")} className='iconmenu' src="/assets/img/menuicon.svg" alt="image" /></span>
                                                </div>


                                                <div className={`updhomemenuWRPAlt barupd ${sideMenuActive ? 'active' : ''}`} onClick={() => setSideMenuActive(!sideMenuActive)}>
                                                </div>
                                                <div className={`updhomemenumenuwrp ${sideMenuActive ? 'active' : ''}`}>
                                                    <div className="navupdflexcon">
                                                        <div className="updatenavlinkcon">
                                                            <ul>


                                                                {/*<li><Link to="/login"> <div className="updnavlinkbtn" > Sign in / Signup</div> </Link></li>*/}
                                                                <li><NavLink to="/about"> <div className="updnavlinktxt" onClick={() => setSideMenuActive(!sideMenuActive)}>About us </div> </NavLink></li>
                                                                <li><NavLink to="/services" > <div className="updnavlinktxt" onClick={() => setSideMenuActive(!sideMenuActive)}> Services</div> </NavLink></li>
                                                                <li><NavLink to="/works" > <div className="updnavlinktxt" onClick={() => setSideMenuActive(!sideMenuActive)}>How It Works </div> </NavLink></li>
                                                                <li><NavLink to="/contact"> <div className="updnavlinktxt" onClick={() => setSideMenuActive(!sideMenuActive)}>Contact Us </div> </NavLink></li>
                                                                <li><Link to="/login"> <div className="updnavlinkbtn "> Sign in / Signup</div> </Link></li>
                                                                <li><a href={socialData?.filter((d) => d.type === "partner-link")[0]?.link} target="_blank"> <div className="updnavlinkbtn partlink">Partner With Us </div> </a></li>
                                                                {/*<li><a> <div className="updnavlinkbtn partlink" >Partner With Us </div> </a></li>*/}
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                            </>
                                        )}

                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
