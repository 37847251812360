
import authAxios from "../services/authAxios";
import UnauthAxios from "../services/unauthAxios";
import ROOT_URL, { ROOT_URL_DOCTOR } from "./api-url";

const apiFunc = {
  refreshToken: () =>
    authAxios({
      method: "GET",
      url: `${ROOT_URL}/users/token/refresh`,
    }).catch((err) => {
      // console.log(err);
    }),
    postUpload: (data) =>
    authAxios({
      method: "POST",
      url: `${ROOT_URL}/upload`,
      data: data,
    }).catch((err) => {
      // console.log(err);
    }),
    postUploada: (data) =>
    authAxios({
      method: "POST",
      url: `${ROOT_URL}/upload_audio`,
      data: data,
    }).catch((err) => {
      // console.log(err);
    }),
    getCategory: () =>
    authAxios({
      method: "GET",
      url: `${ROOT_URL}/category/getAllCategories`,
    }).catch((err) => {
      // console.log(err);
    }),
    getSubCategory: () =>
    authAxios({
      method: "GET",
      url: `${ROOT_URL}/category/getAllSubCategories`,
    }).catch((err) => {
      // console.log(err);
    }),
    getSubCategoryById: (id) =>
    authAxios({
      method: "GET",
      url: `${ROOT_URL}/subCategory/getSubCategoryByCategoryId/${id}`,
    }).catch((err) => {
      // console.log(err);
    }),
    getOptionId: () =>
    authAxios({
      method: "GET",
      url: `${ROOT_URL}/question/generateId`,
    }).catch((err) => {
      // console.log(err);
    }),
    GetDiagnosis: (values) =>
    authAxios({
      method: "POST",
      url: `${ROOT_URL_DOCTOR}/doctor/diagnosis_Getall`,
      data:values
    }).catch((err) => {
      // console.log(err);
    }),
    Medicine_Search: (values) =>
    authAxios({
      method: "POST",
      url: `${ROOT_URL_DOCTOR}/doctor/medicine_search`,
      data:values
    }).catch((err) => {
      // console.log(err);
    }),
    
    
};

export default apiFunc;
