let ROOT_PINCODE = 'https://api.postalpincode.in/pincode'
let IMAGE_URL = 'https://d2o16grlb19pkv.cloudfront.net/'

// let ROOT_URL ='http://staging.alphonic.net.in:6500/api/v1/c'
let ROOT_URL = 'https://api.gomedicx.com/api/v1/c'

// let ROOT_URL_COOMAN ='http://staging.alphonic.net.in:6500/api/v1/s'
let ROOT_URL_COOMAN = 'https://api.gomedicx.com/api/v1/s'

// let ROOT_URL_DOCTOR ='http://staging.alphonic.net.in:6500/api/v1/d'
let ROOT_URL_DOCTOR = 'https://api.gomedicx.com/api/v1/d'

/* if(window.location.hostname === 'localhost' || window.location.hostname === '127.0.0.1'){
    console.log(1);
    // ROOT_URL ='https://jsonplaceholder.typicode.com'
}else{
    // TODO: Server API URL
    // ROOT_URL ='https://jsonplaceholder.typicode.com'
    console.log(2);
} */

export { IMAGE_URL, ROOT_URL_COOMAN, ROOT_PINCODE, ROOT_URL_DOCTOR };
export default ROOT_URL;
