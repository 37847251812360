import { useState } from 'react';
import { BrowserRouter } from 'react-router-dom';
import Layout from './layout/layout';
import { UserContext, UserProvider } from "./context/theme";
import 'react-toastify/dist/ReactToastify.css';
// import 'react-toastify/dist/ReactToastify.css';
function App() {
  const [isLogin, setLogin] = useState(false);

  const reset = () => {
    setLogin(false);
  }

  return (
    <BrowserRouter>
      <UserProvider>
        <UserContext.Consumer>
          {({ auth }) => (
            <Layout />
          )}
        </UserContext.Consumer>
      </UserProvider>
    </BrowserRouter>
  );
}

export default App;
