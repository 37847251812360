import React from 'react'

export const ErrorMessage = (props) => {
  const { formik, name, parent } = props;
  return (
    <>
      {parent ? (
        <>
          {formik.touched?.parent?.[name] && formik.errors?.parent?.[name] ? (
            <div className="errorMsg">{formik.errors?.parent?.[name]}</div>
          ) : null}
        </>
      ) : (
        <>
          {formik.touched?.[name] && formik.errors?.[name] ? (
            <div className="errorMsg">{formik.errors?.[name]}</div>
          ) : null}
        </>
      )}
    </>
  )
}
